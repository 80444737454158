html,
body {
  padding: 0;
  margin: 0;
}

.App {
  font-family: "Segoe UI", sans-serif;
  font-size: small;
  text-align: center;
}

:root {
  --font-color: #eee;
  --background-color: #333;
  --link-color: rgb(108, 176, 199);
  --newfile-link-color: lightblue
}

body {
  background-color: var(--background-color);
  color: var(--font-color);
}

h6 {
  font-size: small;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  text-align: left;
}

a {
  color: var(--link-color);
}


ul {
  margin-left: 2%;
}

ul li {
  margin: 2px;

  text-align: left;
}



ul a {
  text-decoration: none;
  color: var(--link-color);
  font-size: smaller;
}

ul a:hover {
  color: var(--bs-cyan);
}

a.nav-link {
  color: var(--bs-teal);
}

.Resizer {
  background: var(--font-color);
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  background: var(--font-color);
  height: 1px;
  margin: -5px 0;
  border-top: 1px solid rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 2px solid rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.file-header {
  margin-top: 6px;
  margin-bottom: 0px;
  margin-right: 5px;
  margin-left: 1px;
  padding: 3px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--bs-gray);
  text-align: left;
  height: 20px;
  cursor: pointer;
}



.output {
  text-align: center;
  text-decoration: underline;
}

.unselectedFile {
  list-style-type: disclosure-closed;
}

.selectedFile {
  list-style-type: disclosure-open;
}

.buttonPane {
  background-color: var(--bs-dark);
  flex: 0 0 auto;
  position: relative;
  outline: none;
  height: 30px;
  display: flex;
}

.btn-dark {
  background-color: var(--bs-dark);
}

.modal-backdrop {
  background-color: var(--bs-dark);
}

.special_modal .modal-content {
  background-color: var(--bs-dark);

}

.svg-container {
  margin: 8px;
  width: auto;
  height: 300px;
  resize: both;
  overflow: auto;
  border: 1px dashed #aaa;
  justify-content: center;
  align-items: center;
  display: flex;
}

svg {
  width: 95%;
  height: 95%;
}

.table-responsive {
  height: 250px;
}

.tableFixHead {
  overflow: auto;
  height: 250px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.inputButton {
  cursor: "pointer";
}

.inputButton:hover {
  cursor: pointer;
}

.clock {
  margin: 8px;
  display: flex;
}

.clock>div {
  flex: 1;
  margin: 5px;
}

.svgtxt {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

svg:active .button-contour {
  transform: rotate(180deg);
  transform-origin: 6px 6px;
}